import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Markdown from "react-markdown";
import Moment from "react-moment";

import replaceIcons from "../components/functions/global";

import Banner from "../components/banner";

export const query = graphql`
	query TournamentQuery($slug: String!) {
		strapiTournament(slug: { eq: $slug}){
			slug
			title
			seo {
				metaTitle
				metaDescription
				shareImage {
					url
				}
			}
			banner {
				title
				description
			}
			description
			dateStart
			dateEnd
			game {
				name
				short
				logo {
					alternativeText
					caption
					url
				}
			}
			url
			image {
				alternativeText
				caption
				url
			}
			country {
				name
				short
				flag {
					alternativeText
					caption
					url
				}
			}
			team {
				slug
				sort
				game
				title
				longDescription
			}
			result
		}
		allStrapiMatch(filter: {tournament: {slug: {eq: $slug}}}, sort: {fields: number, order: ASC}) {
			edges {
				node {
					enemy
					enemyPlayers {
						gamertag
						role {
							name
							sort
						}
						stats {
							name
							value
						}
						mvp
					}
					enemyResult
					homeMatch
					description
					map {
						name
					}
					name
					noceResult
					number
					players {
						player {
							gamertag
						}
						role {
							sort
							name
						}
						stats {
							name
							value
						}
						mvp
					}
					round
					showEnemyStats
					showStats
				}
			}
		}
		site {
			siteMetadata {
				imagePrefix
			}
		}
	}
`;

const TournamentPage = ({ data }) => {
	const atournament = data.strapiTournament;
	const matches = data.allStrapiMatch.edges;
	const site = data.site;

	var sectionIsAlt = false;
	var sectionStyle = 0;
	var styleClassName = 'style1';

	atournament.description = replaceIcons(atournament.description);

	var columnsNoc = [];
	var listNoc = [];
	var columnsEn = [];
	var listEn = [];
	var cellValueHelp = '-';

	return (
		<Layout page={atournament.slug} seo={atournament.seo}>
			<Banner banner={atournament.banner}/>

			<section id="wrapper">
				{sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true}
				<section id="four" className={sectionIsAlt ? "wrapper alt style1" : "wrapper style1"}>
					<div className="inner">
						<a href={"/teams/"+atournament.team.slug} id="back-button" className="special back">back to team</a>

						<h2 className="major title d-flex justify-content-between">Team - {atournament.team.title && atournament.team.title !== '' ? atournament.team.title : atournament.game.name}
						<img className="title" style={{ float: 'none'}} src={site.siteMetadata.imagePrefix + atournament.game.logo.url} alt={atournament.game.logo.alternativeText}/></h2>
						{atournament.image !== null && atournament.image.url ?
							<img className="mb-4" src={site.siteMetadata.imagePrefix + atournament.image.url} alt={atournament.image.alternativeText}/>
						: ''}
						<h2 className="">{atournament.title}</h2>
						<h5>
							{atournament.dateEnd && atournament.dateEnd !== '' && atournament.dateEnd !== atournament.dateStart ? "From: ": "On: "}<Moment format="dddd, D MMMM YYYY">{atournament.dateStart}</Moment>
							{atournament.dateEnd && atournament.dateEnd !== '' && atournament.dateEnd !== atournament.dateStart ? <br/> : ''}{atournament.dateEnd && atournament.dateEnd !== '' && atournament.dateEnd !== atournament.dateStart ? "To: " : ''}{atournament.dateEnd && atournament.dateEnd !== '' && atournament.dateEnd !== atournament.dateStart ? <Moment format="dddd, D MMMM YYYY">{atournament.dateEnd}</Moment> : ''}
							{atournament.country !== null && atournament.country.name ? <br/> : ''}{atournament.country !== null && atournament.country.name ? "Held in: "+atournament.country.name : ''}
							{atournament.country !== null && atournament.country.flag !== null && atournament.country.flag.url ?
								<img className="inline-flag" src={site.siteMetadata.imagePrefix + atournament.country.flag.url} alt={atournament.country.flag.alternativeText}/>
							: ''}
						</h5>
						{atournament.description !== null ? <Markdown source={atournament.description} escapeHtml={false} /> : ''}

						<h3>Result {atournament.result !== null && atournament.result !== '' ? "- "+atournament.result : ''}</h3>
						{matches.length ?
							<div className="table-responsive table-responsive-sm pt-4 mb-3">
								<table className="no-wrap">
									<thead>
										<tr>
											<th>#</th>
											<th>Round</th>
											<th>Matchup</th>
											<th>Map</th>
											<th className="text-center">Result</th>
										</tr>
									</thead>
									<tbody>
										{matches.map((amatch, i) => (
											<tr key={"itemtr"+i}>
												<td>{i+1}.</td>
												<td>
													<a className="d-block no-underline" href={"#match-"+amatch.node.number}>{amatch.node.round}</a>
												</td>
												{amatch.node.homeMatch ?
													<td>
														<a className="d-block no-underline" href={"#match-"+amatch.node.number}>
															{amatch.node.noceResult > amatch.node.enemyResult ?
																<i className="fas fa-trophy" style={{ width: '.75em', marginRight: '0.2em', verticalAlign: '-.165em', }}></i>
															: ''}
															<strong>Nocturnal E-Sports</strong> vs {amatch.node.enemy}
															{amatch.node.noceResult < amatch.node.enemyResult ?
																<i className="fas fa-trophy" style={{ width: '.75em', marginLeft: '0.2em', verticalAlign: '-.165em', }}></i>
															: ''}
														</a>
													</td>
												:
													<td>
														<a className="d-block no-underline" href={"#match-"+amatch.node.number}>
															{amatch.node.noceResult < amatch.node.enemyResult ?
																<i className="fas fa-trophy" style={{ width: '.75em', marginRight: '0.2em', verticalAlign: '-.165em', }}></i>
															: ''}
															{amatch.node.enemy} vs <strong>Nocturnal E-Sports</strong>
															{amatch.node.noceResult > amatch.node.enemyResult ?
																<i className="fas fa-trophy" style={{ width: '.75em', marginLeft: '0.2em', verticalAlign: '-.165em', }}></i>
															: ''}
														</a>
													</td>
												}
												<td>
													<a className="d-block no-underline" href={"#match-"+amatch.node.number}>
														{amatch.node.map !== null ?amatch.node.map.name:<span class="fa-stack" style={{ width: '1em', }}><i class="far fa-map fa-stack-1x" style={{ fontSize: '1em', width: '100%', height: '1em', }}></i><i class="fas fa-slash fa-stack-2x" style={{ fontSize: '1em', width: '100%', height: '1em', }}></i></span>}
													</a>
												</td>
												{amatch.node.homeMatch ?
													<td className="text-center"><a className="d-block no-underline" href={"#match-"+amatch.node.number}><strong>{amatch.node.noceResult}</strong> - {amatch.node.enemyResult}</a></td>
												:
													<td className="text-center"><a className="d-block no-underline" href={"#match-"+amatch.node.number}>{amatch.node.enemyResult} - <strong>{amatch.node.noceResult}</strong></a></td>
												}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						: 'No match played yet in this tournament.'}
						{matches.length ? <p>Detail of every match below.</p> : ''}
					</div>
				</section>

				{matches.length ?
					matches.map((amatch, i) => {

						columnsNoc = ['#','Name','Role'];
						listNoc = [];
						columnsEn = ['#','Name','Role'];
						listEn = [];

						if (amatch.node.showStats) {
							amatch.node.players.map((aplayer, j) => {
								if (aplayer.player !== null && aplayer.role != null) {
									listNoc.push(aplayer.role.sort);
									aplayer.stats.map((astat, k) => {
										if (!columnsNoc.includes(astat.name)) {
											columnsNoc.push(astat.name);
										}
										return '';
									});
								}
								return '';
							});
						}
						if (amatch.node.showEnemyStats) {
							amatch.node.enemyPlayers.map((aplayer, j) => {
								if (aplayer.gamertag !== null && aplayer.role != null) {
									if (!listEn.includes(aplayer.role.sort)) {
										listEn.push(aplayer.role.sort);
									}
									aplayer.stats.map((astat, k) => {
										if (!columnsEn.includes(astat.name)) {
											columnsEn.push(astat.name);
										}
										return '';
									});
								}
								return '';
							});
						}
						listNoc.sort((a, b) => a - b);
						listEn.sort((a, b) => a - b);

						amatch.node.description = replaceIcons(amatch.node.description);

						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
						switch (sectionStyle) {
							case 1: styleClassName = 'style1';
								break;
							case 2: styleClassName = 'style2';
								break;
							case 3: styleClassName = 'style3';
								break;
							case 4: styleClassName = 'style4';
								break;
							default: styleClassName = 'style1';
								break;
						}
						return (
							<section id={"match-"+amatch.node.number} key={"item-"+i} className={sectionIsAlt ? "wrapper alt spotlight "+styleClassName : "wrapper spotlight "+styleClassName}>
								<div className="row inner">
									<div className="col-12">
										<h2>{i+1}. Match vs {amatch.node.enemy}</h2>
										{amatch.node.description !== null ? <Markdown source={amatch.node.description} escapeHtml={false} /> : ''}
									</div>
									<div className="col-12">
										{amatch.node.showStats ?
											<h3>Nocturnal E-Sports{amatch.node.noceResult > amatch.node.enemyResult ? <i className="fas fa-trophy" style={{ width: '.9em', marginLeft: '0.2em', verticalAlign: '-.1em', }}></i>: ''}</h3>
										: ''}
										{amatch.node.showStats ?
											<div className="table-responsive table-responsive-sm mb-3">
												<table className="text-center stats no-wrap">
													<thead>
														<tr>
															{columnsNoc.map((column) => {
																switch (column) {
																	case '#': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	case 'Name': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	case 'Role': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	case 'Champion': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	default: return (<th key={"cellth"+i+column}>{column}</th>);
																}
															})}
														</tr>
													</thead>
													<tbody>
														{listNoc.map((item, k) => {
															return (
																<tr key={"celltr"+i+k}>
																	{amatch.node.players.map((aplayer, j) => {
																		if (aplayer.player !== null && aplayer.role != null) {
																			if (aplayer.role.sort === item) {
																				return (
																					columnsNoc.map((column,r) => {
																						switch (column) {
																							case '#': return(<td className="text-left" key={"cell"+i+j+k+r}>{k+1}</td>);
																							case 'Name': return(<td className="text-left player" key={"cell"+i+j+k+r}>{aplayer.player.gamertag}{aplayer.mvp ? <i class="fas fa-crown"></i> : ''}</td>);
																							case 'Role': return(<td className="text-left" key={"cell"+i+j+k+r}>{aplayer.role.name}</td>);
																							case 'Champion':
																								cellValueHelp = '-';
																								aplayer.stats.map((astat) => {
																									if (astat.name === column) {
																										cellValueHelp = astat.value;
																									}
																									return '';
																								});
																								return(<td className="text-left" key={"cell"+i+j+k+r}>{cellValueHelp}</td>);
																							default:
																								cellValueHelp = '-';
																								aplayer.stats.map((astat) => {
																									if (astat.name === column) {
																										cellValueHelp = astat.value;
																									}
																									return '';
																								});
																								return(<td key={"cell"+i+j+k+r}>{cellValueHelp}</td>);
																						}
																					})
																				);
																			}
																		}
																	})}
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										: ''}
										{amatch.node.showEnemyStats ?
											<h3>{amatch.node.enemy}{amatch.node.noceResult < amatch.node.enemyResult ? <i className="fas fa-trophy" style={{ width: '.9em', marginLeft: '0.2em', verticalAlign: '-.1em', }}></i>: ''}</h3>
										: ''}
										{amatch.node.showEnemyStats ?
											<div className="table-responsive table-responsive-sm mb-3">
												<table className="text-center stats no-wrap">
													<thead>
														<tr>
															{columnsEn.map((column) => {
																switch (column) {
																	case '#': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	case 'Name': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	case 'Role': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	case 'Champion': return (<th className="text-left" key={"cellth"+i+column}>{column}</th>);
																	default: return (<th key={"cellth"+i+column}>{column}</th>);
																}
															})}
														</tr>
													</thead>
													<tbody>
														{listEn.map((item, k) => {
															return (
																<tr key={"celltr"+i+k}>
																	{amatch.node.enemyPlayers.map((aplayer, j) => {
																		if (aplayer.player !== null && aplayer.role != null) {
																			if (aplayer.role.sort === item) {
																				return (
																					columnsEn.map((column,r) => {
																						switch (column) {
																							case '#': return(<td className="text-left" key={"cell"+i+j+k+r}>{k+1}</td>);
																							case 'Name': return(<td className="text-left player" key={"cell"+i+j+k+r}>{aplayer.gamertag}{aplayer.mvp ? <i class="fas fa-crown"></i> : ''}</td>);
																							case 'Role': return(<td className="text-left" key={"cell"+i+j+k+r}>{aplayer.role.name}</td>);
																							case 'Champion':
																								cellValueHelp = '-';
																								aplayer.stats.map((astat) => {
																									if (astat.name === column) {
																										cellValueHelp = astat.value;
																									}
																									return '';
																								});
																								return(<td className="text-left" key={"cell"+i+j+k+r}>{cellValueHelp}</td>);
																							default:
																								cellValueHelp = '-';
																								aplayer.stats.map((astat) => {
																									if (astat.name === column) {
																										cellValueHelp = astat.value;
																									}
																									return '';
																								});
																								return(<td key={"cell"+i+j+k+r}>{cellValueHelp}</td>);
																						}
																					})
																				);
																			}
																		}
																	})}
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										: ''}
									</div>
								</div>
							</section>
						);
					})
				: ''}
			</section>
		</Layout>
	);
};

export default TournamentPage;
